<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <span style="font-size: 18px; font-weight: 400; color: #333"
          >文章分类管理</span
        >
        <el-divider></el-divider>
        <el-button
          type="primary"
          @click="addPage"
          style="float: left; margin: 10px 5px; padding:15px 20px"
          ><i class="el-icon-circle-plus-outline" style="margin-right:5px"></i
          >添加类型</el-button
        >
      </div>
      <el-table
        :data="PageConfigure"
        row-key="CategoryGuid"
        style="width: 100%"
      >
        <el-table-column
          fixed
          label="序号"
          width="80"
          type="index"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="类型名称"
          width="150"
          prop="Name"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="上级类型"
          min-width="200"
          prop="ParentName"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="排序"
          width="80"
          prop="SortNumber"
          align="center"
        >
          <template slot-scope="scope">
            <span style="font-weight:700">{{ scope.row.SortNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型描述" prop="AdminComment" align="center">
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="180"
          prop="DateCreated"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="backSpan"
              @click="eduit(scope.row)"
              style="color: #409eff"
            >
              编辑
            </span>
            <span
              class="backSpan"
              @click="PreDelet(scope.row)"
              style="color: #cc0000"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>

      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog
          :modal-append-to-body="false"
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
          :title="dialongtitle"
          :before-close="closeDialog"
        >
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item label="图标(21*21):">
              <span class="xiaoxx">*</span>
              <img
                class="upImg"
                prop="imgRules"
                :src="imgshow == 1 ? Rowcontent.ImgUrl : defaultimg"
                alt=""
              />
              <el-upload
                :show-file-list="false"
                :action="img_Url"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <el-button style="width:120px;margin-left:10px" type="primary">
                  上传<i class="el-icon-upload el-icon--right"></i>
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="类型名称:"
              :label-width="formLabelWidth"
              prop="Name"
            >
              <el-input
                style="width:340px"
                v-model="Rowcontent.Name"
                placeholder="请输入类型名称"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="类型简称:"
              :label-width="formLabelWidth"
              prop="SortName"
            >
              <el-input
                style="width:340px"
                v-model="Rowcontent.SortName"
                placeholder="请输入类型简称"
                maxlength="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型描述:" :label-width="formLabelWidth">
              <el-input
                style="width:340px"
                type="textarea"
                :rows="5"
                maxlength="200"
                v-model="Rowcontent.AdminComment"
                placeholder="请输入类型描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="上级类型:" :label-width="formLabelWidth">
              <el-cascader
                ref="typeTree"
                style="width:340px"
                v-model="typeTreeValue"
                :options="Alltype"
                :props="AlltypeObj"
                @change="seltype"
                :show-all-levels="false"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="栏目排序:"
              type="number"
              :label-width="formLabelWidth"
              prop="SortNumber"
            >
              <el-input
                style="width:340px"
                v-model="Rowcontent.SortNumber"
                placeholder="请输入文章序号"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="显示栏目:"
              type="number"
              :label-width="formLabelWidth"
            >
              <el-radio
                v-model="Rowcontent.radio"
                @change="changeradio0"
                label="0"
                >不显示</el-radio
              >
              <el-radio
                v-model="Rowcontent.radio"
                @change="changeradio1"
                label="1"
                >显示</el-radio
              >
            </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog()">返回</el-button>
            <el-button :disabled="isDisabled" @click="submitForm('editForm')">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";

export default {
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^[0-9]\d*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    };
    return {
      dialongtitle: "",
      dialogFormVisible: false,
      dialongtitle: "",
      formLabelWidth: "100px",
      Rowcontent: {
        CategoryGuid: "",
        ParentGuid: "",
        ParentName: "",
        ImgUrl: "",
        Name: "",
        AdminComment: "",
        SortNumber: "",
        SortName: "",
        Id: "",
        radio: "0",
        IsSystem: "",
        Deleted: "",
        IsVisible: "",
        IsVisible: ""
      },
      rules: {
        Name: [{ required: true, message: "请完善信息", trigger: "blur" }],
        SortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" }
        ]
      },

      PageConfigure: [], //列表分页数据
      Params: {
        pageIndex: 1,
        pageSize: 10,
        TotalCount: 5,
        TotalPage: 0
      },
      IsApp: false,
      IsEdit: false,
      pageconfigID: "",
      pageName: "",
      deleteStatus: false,
      Alltype: [], //获取所有分类
      AlltypeObj: {
        value: "CategoryGuid",
        label: "Name",
        children: "Children",
        checkStrictly: true
      },
      typeTreeValue: [],
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image",
      defaultimg: require("../../assets/images/defaultimg.jpg"),
      imgshow: "1",
      //解决重复点击问题
      isDisabled: false
    };
  },
  created() {
    this.searchDate();
    this.getalltype();
  },
  mounted() {},
  methods: {
    //获取数据
    searchDate() {
      var _this = this;
      this.$get(this.$api.GetArticleCategoryList, { ...this.Params })
        .then(res => {
          _this.PageConfigure = res.Data;
          // console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch(err => {
          console.log(err);
        });
    },

    //获取所有类型
    getalltype() {
      this.$get(this.$api.GetAllArticleCategory).then(data => {
        let res = data.Data;
        this.Alltype = res;
      });
    },
    startTree() {
      // console.log(this.$refs);
      this.$refs.typeTree.$refs.panel.checkedValue = []; // 清空选中值
      this.$refs.typeTree.$refs.panel.clearCheckedNodes(); // 清空级联选择器选中状态
      this.$refs.typeTree.$refs.panel.activePath = []; // 清除高亮
      this.$refs.typeTree.$refs.panel.syncActivePath(); // 初始化（只展示一级节点）
    },
    seltype() {
      if (this.typeTreeValue && this.typeTreeValue.length > 1) {
        this.Rowcontent.ParentGuid = this.typeTreeValue[ this.typeTreeValue.length - 1];
      } else {
        this.Rowcontent.ParentGuid = this.typeTreeValue[0];
      }
    },

    //单选框
    changeradio0() {
      this.Rowcontent.IsVisible = "false";
    },
    changeradio1() {
      this.Rowcontent.IsVisible = "true";
    },
    closeDialog() {
      this.startTree();
      this.dialogFormVisible = false;
    },

    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "新增文章类型";
      this.dialogFormVisible = true;
      this.Rowcontent.Name = "";
      this.Rowcontent.SortName = "";
      this.Rowcontent.AdminComment = "";
      this.Rowcontent.SortNumber = "";
      this.Rowcontent.IsVisible = "false";
      this.Rowcontent.Id = "0";
      this.typeTreeValue = [];
      this.imgshow = "2";
      this.Rowcontent.ImgUrl = "";
    },
    //编辑
    eduit(val) {
      var _this = this;
      this.IsEdit = true;
      this.dialongtitle = "编辑文章类型";
      this.dialogFormVisible = true;
      this.dialongtitle = "编辑文章类型";
      // console.log(val);
      this.imgshow = "1";
      this.Rowcontent.ImgUrl = val.ImgUrl;
      this.Rowcontent.Name = val.Name;
      this.Rowcontent.SortName = val.SortName;
      this.Rowcontent.AdminComment = val.AdminComment;
      this.Rowcontent.SortNumber = val.SortNumber;
      this.typeTreeValue = val.ParentGuid;
      if (val.IsVisible == false) {
        this.Rowcontent.radio = "0";
      } else {
        this.Rowcontent.radio = "1";
      }
      this.Rowcontent.Id = val.Id;
      this.Rowcontent.CategoryGuid = val.CategoryGuid;
      this.Rowcontent.ParentGuid = val.ParentGuid;
      this.Rowcontent.IsSystem = val.IsSystem;
      this.Rowcontent.Deleted = val.Deleted;
      this.Rowcontent.IsVisible = val.IsVisible;
    },
    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    submitForm(editForm) {
      var _this = this;
      this.isDisabled = true;
      this.entime();
      if (this.Rowcontent.ImgUrl == "" || this.Rowcontent.ImgUrl == null) {
        this.$message({
          message: "请上传图片！",
          type: "error",
          duration: "1500"
        });
        return;
      }
      this.$refs[editForm].validate(valid => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            console.log(this.Rowcontent);
            this.$post(this.$api.UpdateArticleCategory, this.Rowcontent)
              .then(res => {
                if (res.Code == 200) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500"
                  });
                  _this.searchDate();
                }
              })
              .catch(err => {
                console.log(err);
              });
            this.closeDialog();
          } else {
            console.log("新增");
            console.log(this.Rowcontent);
            this.$post(this.$api.AddArticleCategory, this.Rowcontent)
              .then(res => {
                if (res.Code == 200) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500"
                  });
                  _this.searchDate();
                }
              })
              .catch(err => {
                console.log(err);
              });
            this.closeDialog();
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500"
          });
        }
      });
    },

    //删除
    PreDelet(row) {
      this.$confirm("确实删除?", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question"
      })
        .then(() => {
          this.$post(
            this.$api.DeleteArticleCategory + "?articleCategoryid=" + row.Id
          )
            .then(res => {
              if (res.Code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success"
                });
                this.searchDate();
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => console.log(err));
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.pageIndex = val;
      console.log(this.Params.PageIndex);
      this.searchDate();
    },
    handleSizeChange(val) {
      this.Params.pageSize = val;
      console.log(val);
      this.searchDate();
    },

    //上传图片
    // httpRequest(data){
    //   console.log(data);
    //   let _this=this;
    //   let rd=new FileReader();
    //   let file =data.file;
    //   rd.readAsDataURL(file);
    //   rd.onload=function(e){
    //     _this.Rowcontent.Img=this.result
    //   }
    // },
    handleAvatarSuccess(res, file) {
      this.imgshow = "1";
      console.log(res);
      this.Rowcontent.ImgUrl = res.success_list[0];
    },
    beforeAvatarUpload() {
      console.log("ssss");
      console.log(this.Rowcontent);
      console.log(this.Rowcontent.ImgUrl);
    }
  }
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf{
    background: #e6e6e6
    color: #303133
}
/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .backSpan {
    padding: 0px 20px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }
  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}
/*编辑框样式*/
/deep/.edit-form {
    width: 500px;
    .el-dialog__header {
        height: 50px;
        text-align: center;
        background-color: #409eff;

        .el-dialog__title {
        line-height 0px
        font-size: 22px;
        color: #fff;
        }

        .el-dialog__headerbtn {
        display: none;
        }
    }

    .el-form-item .el-textarea__inner {
        resize: none;
    }

    .el-dialog__body {
        padding-bottom: 5px !important;
    }

    .dialog-footer {
        text-align: center;

        .el-button {
        background-color: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
        }

        .el-button:hover {
        background-color: #409eff;
        border-color: #409eff;
        color: #fff;
        }
    }
    img.upImg{
        width 40px
        height 40px
        background-size 100% 100%
        float left
    }
}
//小星星
.xiaoxx{
  color #F56C6C
  position absolute
  top 0
  left -10px
}
</style>
